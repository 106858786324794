<template>
  <div class="header">
    <!-- 顶部 -->
    <div class="breadcrumb">多地远程线上射击比赛系统</div>
    <div class="titleBox">欢迎您～ {{ userInfo.name }}</div>
    <div class="userInfo">
      <el-button class="btnBox" type="primary" @click="dialogVisible = true"
        >修改密码</el-button
      >
      <el-button class="btnBox" type="primary" @click="cancleOnload()"
        >退出登录</el-button
      >
    </div>
    <el-dialog
      title="修改密码"
      top="20vh"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div>
        <p>旧密码</p>
        <el-input show-password v-model="psd.oldPwd"></el-input>
      </div>
      <div>
        <p>新密码</p>
        <el-input show-password v-model="psd.newPwd"></el-input>
      </div>
      <div>
        <p>确认密码</p>
        <el-input show-password v-model="isPsd"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="deteBtn()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePwd } from '@/api/login.js'
export default {
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')), // 用户信息
      dialogVisible: false,
      psd: {
        newPwd: "",
        oldPwd: ""
      },
      isPsd: ''
    }
  },
  created() {
    // console.log(this.userInfo);
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.psd = {
            newPwd: "",
            oldPwd: ""
          }
          this.isPsd = ''
          this.dialogVisible = false;
          done();
        })
        .catch(_ => { });
    },
    // 注销登录
    cancleOnload() {
      this.$confirm(
        '是否退出当前用户？',
        '提示:',
        {
          confirmButtonText: '确定',
          cancleButtonText: '取消',
          type: 'wanring'
        }
      ).then(() => {
        sessionStorage.clear();
        this.$router.replace('/login');
      });
    },
    // 修改密码
    async deteBtn() {
      if (this.isPsd !== this.psd.newPwd && this.psd.newPwd !== '') {
        return this.$message({
          showClose: true,
          message: '密码不一致，请重新输入！',
          type: 'error'
        });
      }
      const res = await updatePwd(this.psd)
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '密码修改成功，请重新登录！',
          type: 'success'
        });
        this.dialogVisible = false;
        this.$router.push('/')
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
::v-deep .el-dialog__body {
  div {
    margin-bottom: 10px;
    p {
      height: 20px;
      line-height: 20px;
      margin: 0;
    }
    .el-input {
      height: 50px;
    }
  }
}
.header {
  // box-shadow: -1px -1px 1px 0px #DAEEFC, 1px 1px 1px 0px #DAEEFC;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 0px rgba(3, 31, 48, 0.15);
  // padding: 0 20px;
  height: 78px;
  .breadcrumb {
    height: 78px;
    font-size: 40px;
    font-family: MFJianHei_Noncommercial-Regular, MFJianHei_Noncommercial;
    font-weight: 400;
    color: #04307e;
    line-height: 78px;
    margin-left: 35px;
  }
  .titleBox {
    height: 78px;
    line-height: 78px;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #04307e;
  }
  .userInfo {
    display: flex;
    align-items: center;
    // height: 60px;
    padding-right: 20px;
    .btnBox {
      width: 109px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background: #06206b;
      border-radius: 15px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      // margin-top: 25px;
      margin-left: 30px;
    }
  }
}
</style>