<template>
  <div class="menuBox">
    <!-- 菜单 -->
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="routerPath()"
      :router="true"
      background-color="#D8E2FE"
      width="198px"
      text-color="#06206B"
      active-text-color="#FFFFFF"
    >
      <div v-for="item in menuData" :key="item.id">
        <div v-if="item.children">
          <el-submenu :index="item.path">
            <span slot="title">{{ item.title }}</span>
            <template v-if="item.children">
              <el-menu-item
                :index="ele.path"
                v-for="ele in item.children"
                :key="ele.id"
                >{{ ele.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </div>
        <div v-else>
          <el-menu-item :index="item.path">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // menuData: [
      //   {
      //     id: 1, title: '赛前准备', path: '/home/eventManagement', children: [
      //       { id: 11, title: '比赛项目管理', path: '/home/competitionProject' },
      //       { id: 12, title: '赛程管理', path: '/home/raceSchedule' },
      //       { id: 13, title: '分组管理', path: '/home/grouping' },
      //     ]
      //   },
      //   {
      //     id: 2, title: '选手信息', path: '/home/playerInformation'
      //   },
      //   { id: 3, title: '场地靶位管理', path: '/home/targetSite' },
      //   {
      //     id: 4, title: '实时赛况', path: '/home/realTime'
      //   },
      //   {
      //     id: 5, title: '成绩查询', path: '/home/scoreQuery'
      //   },
      //   {
      //     id: 6, title: '系统管理', path: '/home/systemManagement', children: [
      //       { id: 61, title: '用户管理', path: '/home/userManagement' },
      //       { id: 62, title: '角色管理', path: '/home/roleMangement' }
      //     ]
      //   },
      // ]

      menuData: JSON.parse(sessionStorage.getItem('userInfo')).menuList
    }
  },
  methods: {
    routerPath() {
      // console.log(this.$route.path)
      // if ((this.$route.path).indexOf('competitionProject') === -1) {
      //   return this.$route.path
      // } else {
      //   return '/home/competitionProject'
      // }
      let url = this.$route.path
      if ((this.$route.path).indexOf('competitionProject') !== -1) {
        // console.log((this.$route.path).indexOf('competitionProject'))

        return '/home/competitionProject'
      } else if ((this.$route.path).indexOf('playerInformation') !== -1) {
        // console.log(2);

        return '/home/playerInformation'
      } else if ((this.$route.path).indexOf('targetSite') !== -1) {
        // console.log(3);

        return '/home/targetSite'
      } else if ((this.$route.path).indexOf('realTime') !== -1) {
        // console.log(4);

        return '/home/realTime'
      } else if ((this.$route.path).indexOf('scoreQuery') !== -1) {
        // console.log(5);

        return '/home/scoreQuery'
      }
      else if ((this.$route.path).indexOf('deviceManagement') !== -1) {
        // console.log(6);

        return '/home/deviceManagement'
      }
      else if ((this.$route.path).indexOf('userManagement') !== -1) {
        // console.log(7);

        return '/home/userManagement'
      }
      else if ((this.$route.path).indexOf('roleMangement') !== -1) {
        // console.log(8);

        return '/home/roleMangement'
      } else {
        // console.log(9);

        return this.$route.path
      }
    }
  },
  created() {
    // console.log(this.routerPath());
    // console.log(this.menuData);
  }
}
</script>

<style scoped lang="scss">
.menuBox {
  width: 198px;
  height: calc(100% - 80px);
  box-shadow: 0px 2px 2px 0px rgba(3, 15, 42, 0.28);
  ::v-deep.el-menu-item {
    width: 198px !important;
    // box-shadow: 0px 2px 2px 0px rgba(3, 15, 42, 0.28);
  }
  ::v-deep.el-menu-item.is-active {
    width: 198px !important;
    background: #06206b !important;
    // background-color: var(--primary);
  }
  ::v-deep.el-menu-vertical-demo.el-menu {
    border: 0px !important;
  }
  ::v-deep.el-submenu__title i {
    color: white !important;
  }
}
</style>