<template>
  <div class="home">
    <el-container>
      <el-aside class="menu">
        <div class="logo"></div>
        <div class="logo1"></div>
        <MenuVue></MenuVue>
      </el-aside>

      <el-container>
        <el-header style="font-size: 16px">
          <HeaderVue></HeaderVue>
        </el-header>
        <el-main>
          <!-- {{$route.meta.title}} -->
          <div class="container" style="overflow-y: hidden">
            <div v-if="this.$route.path === '/home'" class="homeBox">
              <div class="leftBox">
                <div>持续进取</div>
              </div>
              <div class="midBox">
                <div></div>
              </div>
              <div class="rightBox">
                <div>终将荣耀</div>
              </div>
            </div>
            <div
              v-else-if="this.$route.path !== '/home/scoreQuery'"
              class="breadcrumb"
            >
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <!-- {{$route.meta.breadcrumbList}} -->
                <template>
                  <el-breadcrumb-item
                    v-for="item in $route.meta.breadcrumbList"
                    :key="item.id"
                    :to="{ path: `${item.path}` }"
                    ><span>{{ item.name }}</span></el-breadcrumb-item
                  >
                </template>
              </el-breadcrumb>
            </div>
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MenuVue from "../../components/menu/Menu.vue";
import HeaderVue from "../../components/header/Header.vue";
export default {
  name: 'Home',
  data() {
    return {
    };
  },
  created() {
    // console.log(this.$route.path);
  },
  components: {
    MenuVue,
    HeaderVue,
  },
};
</script>

<style scoped lang="scss">
.el-aside::-webkit-scrollbar {
  display: none;
}
.home {
  position: relative;
  // height: 100vh;
  // overflow-y: scroll;
  // background-color: #5B87A3;
  // box-sizing: border-box;
  // min-height: 100%;
  // overflow: hidden;
  .el-container {
    height: 100%;
    background: #eaefff;
  }
}
.el-header {
  background: #d8e2fe;
  color: #333;
  line-height: 80px;
  height: 80px !important;
  padding: 0;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  // box-shadow: 0px 2px 2px 0px rgba(3, 31, 48, 0.15);
}
.el-aside {
  // overflow-x: hidden;
  box-sizing: border-box;
  // overflow: hidden;
  color: #ecf2ff;
  height: 100vh;
  max-width: 200px;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  // box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);
  // position: absolute;
  // z-index: 2;
  // position: sticky;
  padding: 0;
  background: #d8e2fe;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 78px;
    width: 200px;
    background-color: #d8e2fe;
    background-image: url("~@/assets/hometoplogo.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-shadow: 0px 2px 0px 0px rgba(3, 31, 48, 0.15);
    z-index: 999;
  }
  .logo1 {
    height: 80px;
    width: 100%;
  }
}
::v-deep .el-main {
  background: #eaefff;
  // height: 100%;
  // height: 100vh;
  // overflow: hidden;
  width: 100%;
  // flex-direction: column;
  // flex-grow: 1;
  // align-content: stretch;
  padding-bottom: 0px !important;
  .container {
    background-color: #fff;
    height: calc(100vh - 120px);
    overflow-y: auto !important;
    // box-shadow: -1px -1px 1px 0px #daeefc, 1px 1px 1px 0px #daeefc;
    .homeBox {
      width: 100%;
      height: 100%;
      background: #fffaf7;
      display: flex;
      .leftBox {
        position: relative;
        flex: 1;
        div {
          position: absolute;
          top: 50%;
          left: 0;
          width: 176px;
          height: 62px;
          font-size: 44px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffbb8d;
          line-height: 62px;
          margin-top: -32px;
          margin-left: 200px;
        }
      }
      .midBox {
        position: relative;
        width: 500px;
        height: 500px;
        background-image: url("~@/assets/homebj.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: auto;
        div {
          width: 175px;
          height: 215px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -107px;
          margin-left: -87px;
          background-image: url("~@/assets/homeLogo.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .rightBox {
        position: relative;
        flex: 1;
        div {
          position: absolute;
          top: 50%;
          left: 0;
          width: 176px;
          height: 62px;
          font-size: 44px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffbb8d;
          line-height: 62px;
          margin-top: -32px;
          margin-left: 200px;
        }
      }
    }
    .breadcrumb {
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;
      .el-breadcrumb {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        ::v-deep .el-breadcrumb__inner {
          color: #2b4181;
        }
        ::v-deep .el-breadcrumb__separator {
          color: #2b4181;
        }
      }
    }
  }
}
</style>
